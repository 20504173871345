import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class EventEmiterService {

  private emitChangeSource = new Subject();
  changeEmitted$ = this.emitChangeSource.asObservable();

  private songChangeSource = new Subject();
  changeSong$ = this.songChangeSource.asObservable();

  private addSongSource = new Subject();
  addSong$ = this.addSongSource.asObservable();

  private addPlayListSource = new Subject();
  addPlayListSource$ = this.addPlayListSource.asObservable();

  private songPauseSource = new Subject();
  pauseSong$ = this.songPauseSource.asObservable();

  private relatedSongsPlayList = new BehaviorSubject([]);
  relatedSongs$ = this.relatedSongsPlayList.asObservable();

  private audioPlayerLoaded = new BehaviorSubject(false);
  audioPlayerLoadedCheck$ = this.audioPlayerLoaded.asObservable();

  private currentSongIdGlobal = new BehaviorSubject(false);
  currentSongIdGlobalCheck$ = this.currentSongIdGlobal.asObservable();

  private currentSongIdPlay = new BehaviorSubject(false);
  currentSongIdPlayCheck$ = this.currentSongIdPlay.asObservable();

  private headerReloadSource = new BehaviorSubject(false);
  headerReload$ = this.headerReloadSource.asObservable();

  private videoPlayerLoaded = new BehaviorSubject(false);
  videoPlayerLoadedCheck$ = this.videoPlayerLoaded.asObservable();

  private videoDataPlayerLoaded = new BehaviorSubject(false);
  videoDataPlayerLoadedCheck$ = this.videoDataPlayerLoaded.asObservable();

  private removeSearchtext = new BehaviorSubject(false);
  removeSearchtextCheck$ = this.removeSearchtext.asObservable();

  constructor() { }

  emitVideoPlayerLoaded(isLoaded: any) {
    this.videoPlayerLoaded.next(isLoaded);
  }

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  setDataLoaded(data: any) {
    this.videoDataPlayerLoaded.next(data);
  }

  emitSongChange(songDetail: any) {
    this.songChangeSource.next(songDetail);
  }

  pauseSong(isPauseSong: boolean) {
    this.songPauseSource.next(isPauseSong);
  }


  emitAddSongChange(songDetailQueue: any) {
    let sondDetailsFormated = [];
    sondDetailsFormated = [...sondDetailsFormated, ...JSON.parse(JSON.stringify(this.preparePlayListArry(songDetailQueue)))];
    this.addSongSource.next(sondDetailsFormated);
  }

  playSong(songDetailObj: any) {
    this.emitCurrentSongId(songDetailObj[0].id);
    this.emitSongChange(songDetailObj);
  }

  addtoQueueSong(addSong: any) {
    this.emitAddSongChange(addSong);
  }

  preparePlayListArry(songListArry) {
    let songListResArry = [];
    if (songListArry && songListArry.length > 0) {
      songListArry.forEach((songObjN) => {
        const tempListArry = [];
        const newSongObj: any = {};
        newSongObj.id           = songObjN.song_id;
        newSongObj.title        = songObjN.song_name;
        newSongObj.file         = (songObjN.song ? songObjN.song : ' ');
        newSongObj.poster       = songObjN.song_image;
        newSongObj.description  = '';
        tempListArry.push(newSongObj);
        songListResArry = songListResArry.concat([newSongObj]);
      });
    }
    return songListResArry;
  }

  emitRemoveSearch(isLoaded: any) {
    this.removeSearchtext.next(isLoaded);
  }

  emitAudioPlay(isLoaded: any) {
    this.currentSongIdPlay.next(isLoaded);
  }

  emitAudioPlayerLoaded(isLoaded: any) {
    this.audioPlayerLoaded.next(isLoaded);
  }

  emitCurrentSongId(songId: any) {
    this.currentSongIdGlobal.next(songId);
  }

  emitRelatedSongsList(songsList: any) {
    this.relatedSongsPlayList.next(songsList);
  }

  emitheaderReload(headerReload: any) {
    this.headerReloadSource.next(headerReload);
  }

}
