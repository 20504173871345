import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from '../common-services/api-urls';

@Injectable({
  providedIn: 'root'
})
export class AudioCategoryService {

  constructor(private http: HttpClient, private apiUrls: ApiUrls) {
  }

  getAudioCategory(limit, page, data): Observable<any>{
    return this.http.post(this.apiUrls.audioCategory +'?limit=' + limit + '&page=' + page, data);
  }

  getAudioSubCategory(id, limit, page, data): Observable<any>{
    return this.http.post(this.apiUrls.audioSubCategory + '/' + id +'?limit=' + limit + '&page=' + page, data);
  }

  getAudioSubCategoryTrack(id, limit, page, data): Observable<any>{
    return this.http.post(this.apiUrls.audioSubCategoryTrack + '/' + id +'?limit=' + limit + '&page=' + page, data);
  }

  getAudioCategoryTrack(id, limit, page, data): Observable<any>{
    return this.http.post(this.apiUrls.audioCategoryTrack + '/' + id +'?limit=' + limit + '&page=' + page, data);
  }
}
