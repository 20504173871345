import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from './api-urls';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient, private apiUrls: ApiUrls) {
  }

  getSkipUser(data): Observable<any>{
    return this.http.post(this.apiUrls.skipUser, data);
  }

  getMenu(data): Observable<any>{
    return this.http.post(this.apiUrls.menu, data);
  }

  shareLink(data): Observable<any>{
    return this.http.post(this.apiUrls.shareLink, data);
  }

  shareApp(data): Observable<any>{
    return this.http.post(this.apiUrls.shareApp, data);
  }

  commonSponser(): Observable<any>{
    return this.http.get(this.apiUrls.commonSponserSlider);
  }

  setToken(token) {
    localStorage.setItem("token", token);
  }

  getToken() {
    let token = localStorage.getItem("token");
    return token;
  }

  clearToken() {
    localStorage.clear();
  }

  setLocalStorage(key, storeObj) {
    localStorage.setItem(key, JSON.stringify(storeObj));
  }
  removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  getLocalStorage(key) {
    let itemValue = localStorage.getItem(key);
    if (itemValue != "" && itemValue != null) {
      let decrypted = JSON.parse(itemValue);
      return decrypted;
    } else {
      return '';
    }
  }

}
