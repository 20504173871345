import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ApiUrls {

  navigationMenu: any = []

  constructor() { }

  //menu
  public menu = environment.apiUrl + 'api/menu';

  // share
  public shareLink = environment.apiUrl + 'api/socialmedia';
  public shareApp = environment.apiUrl + 'api/sharemyapp';

  // authentication
  public skipUser = environment.apiUrl + 'api/skipuser';
  public login = environment.apiUrl + 'api/signin';
  public register = environment.apiUrl + 'api/signup';
  public forgotPassword = environment.apiUrl + 'api/forgotpassword';
  public changePassword = environment.apiUrl + 'api/changepassword';

  //uploadImage
  public uploadImage = environment.apiUrl + 'api/uploads';

  //audio
  public audioCategory = environment.apiUrl + 'api/audiocategories';
  public audioSubCategory = environment.apiUrl + 'api/audiosubcategories';
  public audioSubCategoryTrack = environment.apiUrl + 'api/audiolisting/category_id';
  public audioCategoryTrack = environment.apiUrl + 'api/audiolisting/menu_id';
  public audiolikedislike = environment.apiUrl + 'api/favourites';

  //videos
  public videosCategory = environment.apiUrl + 'api/menuitems';
  public videoSubCategory = environment.apiUrl + 'api/videosubcategories';
  public videoSubCategoryTrack = environment.apiUrl + 'api/videolisting/category_id';
  public videoCategoryTrack = environment.apiUrl + 'api/categoryitems';

  //videos-category
  public videosCategoryMenu = environment.apiUrl + 'api/menuitems';
  public tracksCategory = environment.apiUrl + 'api/menuitems';

  //radio
  public radioMenu = environment.apiUrl + 'api/menuitems';

  //home
  public homeMenu = environment.apiUrl + 'api/home';
  public bannerSlider = environment.apiUrl + 'api/homevideoslider';
  public popupVideo = environment.apiUrl + 'api/homeitems';
  public introductionSlider = environment.apiUrl + 'api/walkthrough'
  public homeTopBannerSlider = environment.apiUrl + 'api/bannerslider';
  public landingPage = environment.apiUrl + 'api/landingvideo';

  //profile
  public profiledetail = environment.apiUrl + 'api/profile';
  public profileUpdate = environment.apiUrl + 'api/profile';

  //favorite Audio
  public favAudio = environment.apiUrl + 'api/favourites'

  //favorite Video
  public favVideo = environment.apiUrl + 'api/favouritevideos'

  //Playlist
  public playlist = environment.apiUrl + 'api/playlist'
  public playlistSong = environment.apiUrl + 'api/playlistsongs'

  //video Detail
  public videoDetail = environment.apiUrl + 'api/categoryitemdetails'
  public videolikedislike = environment.apiUrl + 'api/favouritevideos';

  // footer
  public footerDataDetail = environment.apiUrl + 'api/legaldetails';

  //search
  public searchData = environment.apiUrl + 'api/search';

  //booking
  public booking = environment.apiUrl + 'api/booking';
  public contactus = environment.apiUrl + 'api/contactus';

  //Sponsor Slider
  public sponserSlider = environment.apiUrl + 'api/sponsorslider';
  public commonSponserSlider = environment.apiUrl + 'api/sponsor';

}
