<div class="zmovo-main dark-bg">
  <div class="zmoto-inner-page">
    <div class="zmovo-trailor-page">
      <div class="zmovo-trailor pt-50">
        <div class="container">
          <div class="zmovo-trailor-slides">
            <video class="video-js" data-setup='{"controls": true, "autoplay": true, "preload": "auto"}'></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
