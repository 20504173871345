import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AudioCategoryService } from 'src/app/audio-category/audio-category.service';
import { ApiUrls } from 'src/app/common-services/api-urls';
import { CommonService } from 'src/app/common-services/common.service';
import { HomeService } from 'src/app/home/home.service';
import { LiveVideoService } from 'src/app/live-video/live-video.service';
import { EventEmiterService } from 'src/app/shared/event.emmiter.service';
import { VideoCategoryService } from 'src/app/video-category/video-category.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  token: any = '';
  navigationMenu: any = [];
  userDetails: any = {};
  videoCategoryAllData: any = [];
  audioCategoryAllData: any = [];
  limit = 7;
  page = 1;
  shareLinkData: any = [];
  shareAppData: any = [];
  year = new Date().getFullYear();
  menuId = 31;
  urlType: any;
  showLiveVideoMenu: any;
  sponserSliderData: any = [];
  showSponserSlider = false;
  bannerconfig: SwiperConfigInterface = {
    slidesPerView: 1,
    spaceBetween: 0,
    slidesPerGroup: 1,
    loop: true,
    observer: true,
    speed: 1000,
    autoplay: {
      delay: 7000,
    },
    loopFillGroupWithBlank: false,

    breakpoints: {
      640: {
        slidesPerView: 1,
        slidesPerGroup: 1
      },
      990: {
        slidesPerView: 1,
        slidesPerGroup: 1
      },

      1024: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    }
  };

  constructor(public commonService: CommonService,
    private eventEmmiterService: EventEmiterService,
    public apiUrl: ApiUrls,
    private router: Router,
    private videoCategoryService: VideoCategoryService,
    private liveVideoService: LiveVideoService,
    private audioCategoryService: AudioCategoryService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.userDetails = localStorage.getItem('userDetail');
    if(this.token == null && this.userDetails == null) {
      this.skipuser();
    } else if(this.token != null && this.userDetails == null) {
      this.getMenu();
    } else {
      this.getMenu();
    }

  }

  getMenu() {
    if(this.navigationMenu.length == 0) {
      const radio1Object = {
        token: this.commonService.getToken()
      }
      this.liveVideoService.getLiveVideo(this.menuId, radio1Object).subscribe(data => {
        if (data.status == 200) {
          this.urlType = data.data[0]?.url_type;
          this.showLiveVideoMenu = true;
          this.navigationMenu = [];
          const menuObject = {
            "token": this.commonService.getToken()
          }
          this.commonService.getMenu(menuObject).subscribe(data => {
              if(data.status == 200) {
                this.navigationMenu = data.data;
                this.apiUrl.navigationMenu = this.navigationMenu;
              } else if(data.status == 401) {
                this.skipuser();
              }
            })
        }
      });
    }
    const categoryObject = {
      token: this.commonService.getToken(),
      device: "web"
    }
    this.audioCategoryService.getAudioCategory(this.limit, this.page, categoryObject).subscribe(data => {
      if(data.status == 200) {
        this.audioCategoryAllData = data.data;
      }
    })
    this.videoCategoryService.getVideosCategory(this.limit, this.page, categoryObject).subscribe(data => {
      if(data.status == 200) {
        this.videoCategoryAllData = data.data
      }
    })
    this.getSponsers();
    this.socialAndShareData();
  }

  getSponsers() {
    this.commonService.commonSponser().subscribe(data => {
      if(data.status == 200) {
        this.sponserSliderData = data.data;
        this.showSponserSlider = true;
      }
    })

  }

  skipuser() {
    const skipUserObject = {
      "fcm_id": "webuser-"+ Math.floor(Math.random() * 1000000000),
      "device": "web"
    }
    this.commonService.getSkipUser(skipUserObject).subscribe(data => {
      if(data.status == 200) {
        this.commonService.setToken(data.token);
        this.commonService.setLocalStorage('skipuser', 'true');
        this.getMenu();
      }
    })
  }

  socialAndShareData() {
    const shareObject = {
      "token": this.commonService.getToken()
    }
    this.commonService.shareLink(shareObject).subscribe(data => {
      if(data.status == 200) {
        this.shareLinkData = data.data;
      }
    });
    this.commonService.shareApp(shareObject).subscribe(data => {
      if(data.status == 200) {
        this.shareAppData = data.data;
      }
    });
  }

  goTOHome() {
    this.router.navigate(['home']);
  }

  goTOVideos() {
    this.router.navigate(['videos']);
  }

  goTORadio() {
    this.router.navigate(['radio']);
  }

  goTOVideoCategory() {
    this.router.navigate(['video-category']);
  }

  goTOAudioCategory() {
    this.router.navigate(['audio-category']);
  }

  goTOLiveVideoAndChat() {
    this.router.navigate(['live-video']);
  }

  goTOFavoriteAudio() {
    this.router.navigate(['favorite-audio']);
  }

  goTOFavoriteVideo() {
    this.router.navigate(['favorite-video']);
  }

  goTOPlaylist() {
    this.router.navigate(['playlist']);
  }

  goTOPrivacyPolicy() {
    window.open( window.location.origin + '/footer/privacy-policy', '_blank');
  }

  goTOTermsAndConditions() {
    window.open( window.location.origin + '/footer/conditions', '_blank');
  }

  goTOBookings() {
    this.router.navigate(['booking']);
  }

  goTOContactUs() {
    this.router.navigate(['contactus']);
  }

  redirectToAudioCategory(audiodata) {
    if(audiodata.menu_type == "TracksCategory") {
      this.router.navigate(['audio-category', 'subcategory', audiodata.menu_id])
    } else if(audiodata.menu_type == "Music"){
      this.router.navigate(['audio-category', 'category', 'track', audiodata.menu_id])
    }
  }

  redirectToVideoCategory(item) {
    this.router.navigate(['video-category', 'category', 'track', item.category_id])
  }
}
