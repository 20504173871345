import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationUserService } from 'src/app/authentication-user/authentication-user.service';
import { ApiUrls } from 'src/app/common-services/api-urls';
import { CommonService } from 'src/app/common-services/common.service';
import { LiveVideoService } from 'src/app/live-video/live-video.service';
import { EventEmiterService } from 'src/app/shared/event.emmiter.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  token: any = '';
  navigationMenu: any = [];
  isLogin: boolean;
  profileData; any = [];
  userDetails: any = {};
  searchtext = '';
  menuId = 31;
  urlType: any;
  showLiveVideoMenu: any;

  constructor(private router: Router,
    public commonService: CommonService,
    private eventEmmiterService: EventEmiterService,
    private authService: AuthenticationUserService,
    public apiUrl: ApiUrls,
    private liveVideoService: LiveVideoService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private toasterService: ToastrService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.token = localStorage.getItem('token');
        this.userDetails = localStorage.getItem('userDetail');
        if (this.token == null && this.userDetails == null) {
          this.isLogin = false;
          this.skipuser();
        }
        this.getMenu();
        if (event.url == '/' || event.url == '/landing-page') {
          this.renderer.addClass(this.document.body, 'hide-header-footer');
          if(event.url == '/') {
            this.router.navigate(['landing-page']);
          }
        } else {
          this.renderer.removeClass(this.document.body, 'hide-header-footer');
        }
        if (this.userDetails != null) {
          this.getProfile();
        } else {
          this.isLogin = false;
        }
      }
    })
    this.eventEmmiterService.removeSearchtextCheck$.subscribe((removetext) => {
      if (removetext) {
          this.searchtext = '';
        }
      });
    this.eventEmmiterService.headerReload$.subscribe((headerReload) => {
      if (headerReload) {
        if (JSON.parse(localStorage.getItem('userDetail')) != null) {
          const userData = JSON.parse(localStorage.getItem('userDetail'));
          this.isLogin = true;
          this.getMenu();
          this.getProfile();
        } else {
          this.isLogin = false;
        }
      }
    });
  }

  ngOnInit(): void {
  }

  login() {
    this.router.navigate(['authentication', 'login'])
    if (this.router.url != "/authentication/login" && this.router.url != "/authentication/register")
      localStorage.setItem('lasturl', this.router.url)
  }

  skipuser() {
    const skipUserObject = {
      "fcm_id": "webuser-" + Math.floor(Math.random() * 1000000000),
      "device": "web"
    }
    this.commonService.getSkipUser(skipUserObject).subscribe(data => {
      if (data.status == 200) {
        this.commonService.setToken(data.token);
        this.commonService.setLocalStorage('skipuser', 'true');
        this.getMenu();
      }
    })
  }

  getMenu() {
    if (this.navigationMenu.length == 0) {
      const radio1Object = {
        token: this.commonService.getToken()
      }
      this.liveVideoService.getLiveVideo(this.menuId, radio1Object).subscribe(data => {
        if (data.status == 200) {
          this.urlType = data.data[0]?.url_type;
          this.showLiveVideoMenu = true;
          this.navigationMenu = [];
          const menuObject = {
            "token": this.commonService.getToken()
          }
          this.commonService.getMenu(menuObject).subscribe(data => {
            if (data.status == 200) {
              this.navigationMenu = data.data;
              this.apiUrl.navigationMenu = this.navigationMenu;
            } else if (data.status == 401) {
              this.skipuser();
            }
          })
        }
      });
    }
  }

  getProfile() {
    const profileRequest = {
      "token": this.commonService.getToken()
    }
    this.authService.getProfile(profileRequest).subscribe(data => {
      if (data.status == 200) {
        this.isLogin = true;
        this.profileData = data.data[0];
      }
    });
  }

  Logout() {
    this.toasterService.success('Logout successfully', '', { timeOut: 3000 });
    localStorage.removeItem('userDetail');
    localStorage.removeItem('isLogin');
    localStorage.removeItem('skipUser');
    this.router.navigate(['authentication', 'login']);
    $('#exampleModal').modal('hide');
    $('.modal-backdrop').remove();
    this.skipuser();
    this.eventEmmiterService.emitheaderReload(true);

  }

  goTOHome() {
    this.router.navigate(['home']);
  }

  goTOVideos() {
    this.router.navigate(['videos']);
  }

  goTORadio() {
    this.router.navigate(['radio']);
  }

  goTOVideoCategory() {
    this.router.navigate(['video-category']);
  }

  goTOAudioCategory() {
    this.router.navigate(['audio-category']);
  }

  goTOLiveVideoAndChat() {
    this.router.navigate(['live-video']);
  }

  goTOFavoriteAudio() {
    this.router.navigate(['favorite-audio']);
  }

  goTOFavoriteVideo() {
    this.router.navigate(['favorite-video']);
  }

  goTOPlaylist() {
    this.router.navigate(['playlist']);
  }

  goToProfile() {
    this.router.navigate(['profile']);
  }

  goToChangePassword() {
    this.router.navigate(['password', 'change-password']);
  }

  onSubmitSearch() {
    if (this.searchtext != '') {
      if (window.location.href.includes('/search/') == false) {
        this.router.navigate(['search', this.searchtext]);
      } else if (window.location.href.includes('/search/') == true) {
        this.router.navigateByUrl('/search/' + this.searchtext);
      }
    }
    //  else {
    //   this.toasterService.error('Please enter a keyword.', '', { timeOut: 2000 });
    // }
  }

}
