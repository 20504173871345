import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from '../common-services/api-urls';

@Injectable({
  providedIn: 'root'
})
export class LiveVideoService {

  constructor(private http: HttpClient, private apiUrls: ApiUrls) {
  }

  getLiveVideo(menuid, data): Observable<any>{
    return this.http.post(this.apiUrls.radioMenu + '?menu_id=' + menuid + '&menu_type=Videos', data);
  }

  getSponserSlider(data): Observable<any>{
    return this.http.post(this.apiUrls.sponserSlider, data);
  }
}
