import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from '../common-services/api-urls';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient, private apiUrls: ApiUrls) { }

  getHome(data, limit, page): Observable<any>{
    return this.http.post(this.apiUrls.homeMenu + '?limit=' + limit + '&page=' + page , data);
  }

  getBanner(data): Observable<any>{
    return this.http.post(this.apiUrls.bannerSlider , data);
  }

  getpopupVideo(data): Observable<any>{
    return this.http.post(this.apiUrls.popupVideo , data);
  }

  getVideos(data): Observable<any>{
    return this.http.post(this.apiUrls.videoSubCategory + '/10?limit=2&page=1' , data);
  }

  getAudioLikeDislike(data): Observable<any>{
    return this.http.post(this.apiUrls.audiolikedislike + '?type=AddRemoveSong', data);
  }

  createPlaylist(data): Observable<any>{
    return this.http.post(this.apiUrls.playlist + '?type=PlaylistAdd', data);
  }

  playlistList(data): Observable<any>{
    return this.http.post(this.apiUrls.playlist + '?type=PlaylistList', data);
  }

  removePlaylist(data): Observable<any>{
    return this.http.post(this.apiUrls.playlist + '?type=PlaylistRemove', data);
  }

  addSongToPlaylist(data): Observable<any>{
    return this.http.post(this.apiUrls.playlistSong + '?type=AddPlaylistSongs', data);
  }

  playlistSonglisting(limit, page, data): Observable<any>{
    return this.http.post(this.apiUrls.playlistSong + '?type=PlaylistSongsList&limit=' + limit + "&page=" + page, data);
  }

  topHomebannerSlider(data): Observable<any>{
    return this.http.post(this.apiUrls.homeTopBannerSlider, data);
  }

  introducitonSlider(): Observable<any>{
    return this.http.get(this.apiUrls.introductionSlider);
  }

  landingPageVideo(): Observable<any> {
    return this.http.get(this.apiUrls.landingPage)
  }

}
