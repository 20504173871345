import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventEmiterService } from 'src/app/shared/event.emmiter.service';
import videojs from 'video.js';

declare var $: any;

@Component({
  selector: 'app-video-miniplayer',
  templateUrl: './video-miniplayer.component.html',
  styleUrls: ['./video-miniplayer.component.css']
})
export class VideoMiniplayerComponent implements OnInit, OnDestroy {

  player: any;
  @ViewChild('target', { static: true }) target: ElementRef;

  constructor(private eventEmiterService: EventEmiterService) { }

  ngOnInit(): void {
    this.eventEmiterService.videoDataPlayerLoadedCheck$.subscribe((options: any) => {
      // var PlayerData = {
      //   autoplay: true,
      //   sources: {
      //       // src: this.videoDetail.videos_link,
      //       src: 'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8',
      //       type: 'application/x-mpegURL',
      //   }
      // }
      var videoUrl = 'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8';
      this.player = videojs(document.querySelector('.video-js'));
      this.player.src({src: videoUrl, type: 'application/x-mpegURL'});
      // this.player.poster(this.videoDetail.videos_image);
      var self = this;
      var onVideoPlay = function () {
        $(".video-js").css("height", 0);
        $(".vjs-control-bar").css("display", 'none');
        $('.vjs-picture-in-picture-control').click();
      }
      setTimeout(()=> {
        self.player.play();
      }, 1000);
      this.player.on('play', onVideoPlay);
    })
  }

  ngOnDestroy() {
    $('.vjs-picture-in-picture-control').click();
  }

}
