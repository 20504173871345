import { CommonService } from 'src/app/common-services/common.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from '../common-services/api-urls';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationUserService {

  constructor(private http: HttpClient, private apiUrls: ApiUrls, private commonService: CommonService) {
  }

  uploadsImage(data): Observable<any>{
    return this.http.post(this.apiUrls.uploadImage, data);
  }

  registration(data): Observable<any>{
    return this.http.post(this.apiUrls.register, data, {headers : {
      'Content-Type': 'application/json',
      'Authorization': this.commonService.getToken()
    }});
  }

  login(data): Observable<any>{
    return this.http.post(this.apiUrls.login, data,{headers : {
      'Content-Type': 'application/json',
      'Authorization': this.commonService.getToken()
    }});
  }

  getProfile(data): Observable<any> {
    return this.http.post(this.apiUrls.profiledetail + '?type=ProfileDetails', data);
  }

  forgotPassword(forgotPasswordObject): Observable<any> {
    return this.http.post(this.apiUrls.forgotPassword , forgotPasswordObject);
  }
}
