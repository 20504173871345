<div *ngIf="isShowQueue" id="queue_list" class="queueList">
  <div class="play-list">
      <div class="song-listing-title">
          <h5>Cue</h5>
          <div class="queue-wrapper-btn">
              <div class="btn-wrapper-queue-list">
                  <button (click)="clearPlayList()" class="btn-radius btn-tran queue-list btn">
                      Clear
                  </button>
              </div>
              <div (click)="toggleQueue()" class="btn-wrapper">
                  <a>
                      <img alt="" src="../../../assets/images/svg/close.svg">
                  </a>
              </div>
          </div>
      </div>
      <ul class="play-list-song queueList">
          <li class="all-wrapper-list" *ngFor="let item of songRawData">
              <span class="wrapper-list" (click)="playSongFromQueue(item.id)">
                  <!-- <span *ngIf="currentSongId==item.id" class="play-icon playing">
                      <img class="play-gif" alt="" [src]="'assets/images/song-details/play.gif'" /></span> -->
                  <!-- <span *ngIf="currentSongId!=item.id" class="play-icon" >
                      <img class="play-gif" alt="" [src]="'../../../assets/images/profile/grey-play.svg'" /></span> -->
                  <span class="loader-static">
                      <span class="loader-dyanamic">
                          <span class="song-play-poster">
                              <img alt="" src="{{item.poster}}" *ngIf="item.poster !== null && item.poster !== ''" />
                          </span>
                      </span>
                  </span>
                  <span class="song-name">

                      <span class="song-name-p">{{item.title}}</span>
                      <span class="song-artist" [innerHtml]="item.description"></span>
                  </span>
              </span>
              <a class="remove-song" (click)="removeFromQueue(item.id)">
                  <div class="">
                      <img alt="" src="../../../assets/images/svg/close.svg">
                  </div>
              </a>
          </li>
      </ul>
      <div class="dropdown-menu option-play-list">
          <div class="song-play-list ">
              <div class="song-listing-title">
                  <!--<h5>Queue </h5>-->
                  <div class="btn-wrapper">
                      <a><img src="../../../assets/images/svg/close.svg" alt="" /></a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- <div *ngIf="isPlayerLoaded" id="queue_btn" (click)="toggleQueue()">
  <a class="queue-text">Music On Cue</a>
</div> -->
<div [ngClass]="(isPlayerLoaded)?'show-player':'hide-player'" style="width:100%; float:left" id="player"></div>
