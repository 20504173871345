import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from '../common-services/api-urls';

@Injectable({
  providedIn: 'root'
})
export class VideoCategoryService {

  constructor(private http: HttpClient, private apiUrls: ApiUrls) {
  }

  getVideosCategory(limit, page, data): Observable<any>{
    return this.http.post(this.apiUrls.videosCategory + '?menu_id=30&menu_type=VideosCategory&' + 'limit=' + limit + '&page=' + page, data);
  }

  getVideoSubCategory(id, limit, page, data): Observable<any>{
    return this.http.post(this.apiUrls.videoSubCategory + '/' + id + '?limit=' + limit + '&page=' + page, data);
  }

  getVideoSubCategoryTrack(id, limit, page, data): Observable<any>{
    return this.http.post(this.apiUrls.videoSubCategoryTrack + '/' + id +'?limit=' + limit + '&page=' + page, data);
  }

  getVideoCategoryTrack(id, limit, page, data): Observable<any>{
    return this.http.post(this.apiUrls.videoCategoryTrack + '?menu_id=30&category_id='+ id + '&category_for=Videos&limit=' + limit + '&page='+ page, data);
  }
}
