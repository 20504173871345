import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/home/home.service';
import videojs from 'video.js';

declare var $: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  player: any;
  videoUrlType: any;
  videoData: any = [];
  showVideo:boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private homeService: HomeService) {
                this.showVideo = true;
  }

  ngOnInit(): void {
    this.homeService.landingPageVideo().subscribe(data => {
      if(data.status == 200) {
        this.videoData = data.data[0];
        var videoType = '';
        if (this.videoData.video_file.includes('mp4') == true) {
          videoType = 'video/mp4';
        } else if (this.videoData.video_file.includes('m3u8') == true) {
          videoType = 'application/x-mpegURL';
        }
        var videoUrl = this.videoData.video_file;
        this.player = videojs(document.querySelector('.video-js'));
        this.player.src({ src: videoUrl, type: videoType });
        var self = this;
        setTimeout(()=> {
          self.player.play();
        }, 1500);
      }
    })

  }
  EnterWebsite() {
    this.router.navigate(['home']);
  }

}
