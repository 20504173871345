import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmiterService } from 'src/app/shared/event.emmiter.service';

declare var $: any;
declare const Playerjs: any;

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css']
})
export class AudioPlayerComponent implements OnInit {

  isPlayerLoaded: any = false;
  player: any;
  playList: any = [];
  songRawData: any = [];
  currentSongId: any = '';
  isShowQueue: any = false;

  constructor( private eventEmmiterService: EventEmiterService,
              //  private homeService: HomeService,
              private renderer: Renderer2,
               private router: Router) {

    this.eventEmmiterService.audioPlayerLoadedCheck$.subscribe((data: any) => {
        console.log('data', data);
        if (!data && this.player) {
        this.player.api('pause');
        this.player = undefined;
        this.isPlayerLoaded = false;
      }
    });
    if (this.router.url == '/videos') {
      this.player.api('pause');
      this.isPlayerLoaded = false;
    }
    this.eventEmmiterService.changeSong$.subscribe((data: any) => {
      this.setAudioPlayer(data);
    });
    this.eventEmmiterService.addSong$.subscribe((queueData: any) => {
      if (queueData) {
        const isAddedArry = this.songRawData.filter((songRawObj) => {
          return songRawObj.id == queueData[0].id;
        });
        if (isAddedArry.length == 0) {
          const isPlayIntialized = ((this.playList && this.playList.length > 0) ? true : false);

          this.songRawData = this.songRawData.concat(queueData);
          this.playList = [...JSON.parse(JSON.stringify(this.songRawData))];
          if (isPlayIntialized) {
            this.player.api('playlist', this.playList);
          } else {
            this.eventEmmiterService.emitCurrentSongId(this.songRawData[0].id);
            this.setAudioPlayer(this.songRawData);
          }

        }

      }
    });
  }

  ngOnInit(): void {
    // if (this.router.url == '/videos') {
    //   this.player.api('pause');
    //   this.isPlayerLoaded = false;
    // }
  }

  footerClass() {
    const footerClass = document.getElementById('footer-space');
    if (footerClass) {
      this.renderer.addClass(footerClass, 'player-bottom');
    }
  }

  setAudioPlayer(songData) {
    this.isPlayerLoaded = true;
    // this.playList = [
    //   {'playlist_id': 's1', 'id': 's1', 'title': 'First', 'file': 'https://outputffmpeg.s3.amazonaws.com/encoded/1001/index.m3u8'},
    // ];
    this.currentSongId = songData[0].id;
    this.songRawData = JSON.parse(JSON.stringify(songData));
    this.playList = JSON.parse(JSON.stringify(songData));
    this.player = new Playerjs(
      {
        id: 'player',
        autoPlay: 1,
        file: this.playList
      }
    );
    this.player.api('title', this.playList[0].title);

    document.getElementById('player').addEventListener('init', (event) => {
      this.initPlayer(event, this);
    });
    document.getElementById('player').addEventListener('pause', this.onPause);
    document.getElementById('player').addEventListener('end', this.onEnd);
    document.getElementById('player').addEventListener('new', (event) => {
      this.onNew(event, this);
    });
    document.getElementById('player').addEventListener('audiotrack', (event) => {
      this.onNext(event, this);
    });
    // this.isShowQueue = true;
  }

  onNext(event, self) {
    console.log('playlist_id', self.player.api('playlist_id'));
    console.log('audiotrack event', event);
    console.log('audiotrack id', event.id);
    console.log('audiotrack data', event.data);
    this.player.unmute();
  }

  onNew(event, self) {
    if (self.player.api('playlist_id') != self.currentSongId) {
      console.log('not same');
      self.player.api('stop');
      this.player.api('unmute');
      self.eventEmmiterService.emitCurrentSongId(self.player.api('playlist_id'));
      this.playSongFromQueue(self.player.api('playlist_id'));
      // this.addToWatchList(self.player.api('playlist_id'));
    }

    console.log('sondadata iddd', self.currentSongId);
    console.log('playlist_id', self.player.api('playlist_id'));
    console.log('New event', event);
    this.player.api('unmute');
    // this.addToWatchList(self.player.api('playlist_id'));
  }

  onPause(event) {
    console.log('Pause event', event);
    console.log('Pause id', event.id);
    console.log('Pause data', event.data);
    this.eventEmmiterService.emitAudioPlay(false);
  }

  onEnd(event) {
    console.log('End Song Event', event);
    console.log('End Song id', event.id);
    console.log('End Song data', event.data);
    this.eventEmmiterService.emitAudioPlay(false);
  }

  initPlayer(event, self) {
    if (self.player) {
      self.eventEmmiterService.emitAudioPlayerLoaded(true);
      self.eventEmmiterService.emitAudioPlay(true);
      this.footerClass();
    } else {
      self.eventEmmiterService.emitAudioPlayerLoaded(false);
      self.eventEmmiterService.emitAudioPlay(false);
    }
  }

  clearPlayList() {
    const afterRemovedSongList = this.songRawData.filter((queueSongObj) => queueSongObj.id === this.currentSongId);
    this.songRawData = JSON.parse(JSON.stringify(afterRemovedSongList));
    this.playList = JSON.parse(JSON.stringify(afterRemovedSongList));

    this.player.api('playlist', this.playList);

  }
  toggleQueue() {
    if (this.isShowQueue == true) {
      this.isShowQueue = false;
    } else {
      this.isShowQueue = true;
    }
  }

  playSongFromQueue(item) {
    if (this.currentSongId != item) {
      this.eventEmmiterService.emitCurrentSongId(item);
      this.getSongFromQueue(item);
    }
  }
  getSongFromQueue(item) {
    const songIndex = this.playList.findIndex((playSongObj) => {
            return playSongObj.pjs_id === item;
    });
    if (songIndex !== -1) {
      // this.playList[songIndex].file = item.song;
      this.player.api('unmute');
      this.currentSongId = this.playList[songIndex].pjs_id;
      this.player.api('find', this.playList[songIndex].pjs_id);
      this.player.api('play', this.playList[songIndex].file);
      this.player.api('title', this.playList[songIndex].title);
      this.player.api('unmute');
      this.eventEmmiterService.emitAudioPlay(true);
      // this.addToWatchList(this.playList[songIndex].pjs_id);
    }
  }

  removeFromQueue(songId) {
    if(this.currentSongId == songId) {
      return true
    } else {
      const afterRemovedSongList = this.songRawData.filter((queueSongObj) => queueSongObj.id !== songId);
      this.songRawData = JSON.parse(JSON.stringify(afterRemovedSongList));
      this.playList = JSON.parse(JSON.stringify(afterRemovedSongList));

      this.player.api('playlist', this.playList);
      if (this.playList && this.playList.length == 0) {
        this.player = undefined;
        this.eventEmmiterService.emitAudioPlayerLoaded(false);
        this.eventEmmiterService.emitAudioPlay(false);
      }
    }
  }

  // addToWatchList(id) {
  //   this.homeService.addToWatchList(id).subscribe((data) => {
  //     console.log(data);
  //     if(this.player) {
  //       this.player.api('unmute');
  //     }
  //   });
  // }
}
